import qs from 'qs'
import type { Filters } from '@/types'

export const useGetPhonesList = async (query: Filters) => {
  const queryFormatted = qs.stringify(query, { arrayFormat: 'comma', encode: false })

  const { data, error } = await useAPIFetch(`/voice-calls/locals?${queryFormatted}`, {
    key: queryFormatted,
  })

  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useCreatePhone = async (body: object) => {
  const { status } = await useAPIFetch(`/voice-calls/locals`, { method: 'POST', body })

  return status
}

export const useUpdatePhone = async (id: number, body: object) => {
  const { status } = await useAPIFetch(`/voice-calls/locals/${id}`, { method: 'PATCH', body })

  return status
}

export const useUpdateStatusPhone = async (id: number, body: object) => {
  await useAPIFetch(`/voice-calls/locals/${id}/status`, { method: 'PATCH', body })
}

export const useUpdateCanSms = async (id: number, body: object) => {
  await useAPIFetch(`/voice-calls/locals/${id}/can-sms`, { method: 'PATCH', body })
}

export const useDeletePhones = async (body: object) => {
  await useAPIFetch(`/voice-calls/locals`, { method: 'DELETE', body })
}
